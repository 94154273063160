import React, { useState } from "react";
import "../../src/App.css";
import Particle from "../components/Particle";
import Navbar from "../components/Navbar"

const Testimonials = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    const contact = { name, email, message };

    fetch("https://formsubmit.co/ajax/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(contact),
    })
      .then((response) => response.json())
      .then((data) => console.log(data))
      .catch((error) => console.log(error));

    setName("");
    setEmail("");
    setMessage("");
  };

  return (
    <div id="testContain">
      <Particle />
      <Navbar />
      
        <h1 id="testH1">Testimonials</h1>
        <br></br>
        <div className="testimony1 card-body">
          <p id="testCenter">
          I would recommend Lauren to any, and everyone looking to color their hair! I am so pleased- AND she was a joy to talk to you while I was there 😊 you can tell she does her research and is an expert in her field. Great job, Lauren!
            <br></br>
            -Kelly
            <br></br>⭐ ⭐ ⭐ ⭐ ⭐
          </p>
        </div>
        <div className="testimony1 card-body">
          <p id="testCenter">
          Great experience with a consult so we knew we understood my hair goals and what was achievable and affordable. Made me feel very confident in our plan and her skills to help me get my hair healthy again.
            <br></br>
            -Katie
            <br></br>⭐ ⭐ ⭐ ⭐ ⭐
          </p>
        </div>
        <div className="testimony2 card-body">
          <p id="testCenter">
          I’ve been going to Lauren for years and I’ve been a satisfied customer every time, it’s why I keep going back! She’s a master with color and my cut always looks fantastic. I highly recommend Lauren and her services.
            <br></br>
            -Carrie
            <br></br>⭐ ⭐ ⭐ ⭐ ⭐
          </p>
        </div>
        <div className="testimony1 card-body">
          <p id="testCenter">
          before seeing Lauren, I was really unhappy with my current extensions and color. I saw Lauren and she turned my frown upside down!
            <br></br>
            -Tori
            <br></br>⭐ ⭐ ⭐ ⭐ ⭐
          </p>
        </div>
        <div className="testimony2 card-body">
          <p id="testCenter">
          You always make my hair look friggin FANTASIC no matter what I ask, you make it happen! Thank you, Lauren!
            <br></br>
            -Rebecca
            <br></br>⭐ ⭐ ⭐ ⭐ ⭐
          </p>
        </div>
        <div className="testimony1 card-body">
          <p id="testCenter">
          Go see Lauren, she’s fantastic! She is thoughtful and careful, listens and takes her time with your haircut. Her new location is easy to find and park, space is cute, clean, and quiet. The soothing warm towel wrap after washing my hair today was so relaxing. Thanks for another great experience
            <br></br>
            -Karen
            <br></br>⭐ ⭐ ⭐ ⭐ ⭐
          </p>
        </div>
        <div className="testimony2 card-body">
          <p id="testCenter">
          Lauren did an absolutely amazing job on my hair, and was so sweet and made me feel very comfortable!
            <br></br>
            -Akira
            <br></br>⭐ ⭐ ⭐ ⭐ ⭐
          </p>
        </div>
        <div className="testimony1 card-body">
          <p id="testCenter">
            Lauren is the best hairdresser that I have ever had! She has been
            doing my hair for many years now. I have had highlights and color.
            She is amazing at color! She just knows what I want! She also
            introduced me to a keratin treatment that changed the texture of my
            hair. I have very thick and coarse hair. After this treatment my
            hair feels smooth and soft. It makes my hair feel thinner which I.
            Turn makes it easier to style for months! Thanks Lauren you are
            amazing!!
            <br></br>
            -Paula
            <br></br>⭐ ⭐ ⭐ ⭐ ⭐
          </p>
        </div>
        <div className="testimony2 card-body">
          <p id="testCenter">
            Not only is Lauren an amazing hair stylist and coloring wizard but
            she’s also a super down to earth person who makes you feel
            comfortable in the chair. I never am able to articulate exactly what
            I want with my hair but after discussion and her offering advice,
            she always NAILS my color and cuts! Have not had a better hair
            stylist than her when it comes to coloring perfection!
            <br></br>
            -Casey
            <br></br>⭐ ⭐ ⭐ ⭐ ⭐
          </p>
        </div>
        <div className="testimony1 card-body">
          <p id="testCenter">
            Lauren is the best! I’ve been going to her for over a year and am so
            happy every time I leave her salon. She truly listens to me as far
            as what I want done and knocks it out of the park. Plus she’s so fun
            to get to know!! 😊 HIGHLY recommend!
            <br></br>
            -Maddie
            <br></br>⭐ ⭐ ⭐ ⭐ ⭐
          </p>
        </div>
        <div className="testimony2 card-body">
          <p id="testCenter">
            I have been seeing Lauren for haircuts for years and she is amazing!
            I always love my hair when I leave, whether it’s a short cut or a
            long cut and she’s great to chat to as well! I even bring my son for
            his haircuts and he looks great too! 😉
            <br></br>
            -Emma
            <br></br>⭐ ⭐ ⭐ ⭐ ⭐
          </p>
        </div>

        <div className="testimony1 card-body">
          <p id="testCenter">
            Lauren has been doing my hair for about 4 years now. It doesn’t seem
            like it’s been that long but she’s been so incredible and making my
            hair visions come to life. She’s the best!
            <br></br>
            -Bre
            <br></br>⭐ ⭐ ⭐ ⭐ ⭐
          </p>
        </div>
        <div className="testimony2 card-body">
          <p id="testCenter">
            Lauren is the best hairstylist I’ve ever had. Even though she’s
            already an expert, she’s always pushing herself to learn and improve
            the service she provides her clients. I always leave her chair
            lookin’ 💯
            <br></br>
            -Reagan
            <br></br>⭐ ⭐ ⭐ ⭐ ⭐
          </p>
        </div>
        <div className="testimony1 card-body">
          <p id="testCenter">
            All you men - best "man" haircuts ever! Lauren listens to what you
            describe and delivers exactly what you wanted!
            <br></br>
            -Jeff
            <br></br>⭐ ⭐ ⭐ ⭐ ⭐
          </p>
        </div>
        <div className="testimony2 card-body">
          <p id="testCenter">
            I had such a great experience at moonstone studio! Lauren is so
            amazing, I got a Cut and highlight, my blonde is so bright and
            beautiful I absolutely love it! Her space is so clean, beautiful and
            relaxing, I definitely recommend this salon!
            <br></br>
            -Bailey
            <br></br>⭐ ⭐ ⭐ ⭐ ⭐
          </p>
        </div>
        <div className="testimony1 card-body">
          <p id="testCenter">
            Lauren pays such close attention to what I want and always delivers!
            <br></br>
            -Jason
            <br></br>⭐ ⭐ ⭐ ⭐ ⭐
          </p>
        </div>
        <div className="testimony2 card-body">
          <p id="testCenter">
            I highly recommend Lauren! She knows her craft!
            <br></br>
            -Jamie
            <br></br>⭐ ⭐ ⭐ ⭐ ⭐
          </p>
        </div>
        <div className="testimony1 card-body">
          <p id="testCenter">
            Great experiences and conversations! also a great masc haircuts!
            <br></br>
            -Max
            <br></br>⭐ ⭐ ⭐ ⭐ ⭐
          </p>
        </div>
        <div className="testimony2 card-body">
          <p id="testCenter">
            Amazing! No one better for cut and color.
            <br></br>
            -Judy
            <br></br>⭐ ⭐ ⭐ ⭐ ⭐
          </p>
        </div>
        <br></br>
        <br></br>
        <a
          href="https://www.google.com/search?q=moonstone+studio&oq=moonstone&aqs=chrome.0.69i59j69i57j46i433i512j0i433i512j0i512j69i61j69i65j69i60.3179j0j7&sourceid=chrome&ie=UTF-8#lrd=0x8804fff051cb7b01:0xb1907ac2e3cb1ba6,1,,,,"
          id="reviewLink"
        >
          Leave a review!
        </a>
      </div>
  );
};

export default Testimonials;
