import "../../src/App.css";
// import footImg from "../images/GalleriesCOM/LogoCOM/footer-removebg-preview.png";

const Footer = () => {
  return (
    <div id="footer">
      <div id="foot2">
        <br></br>
        <a href="https://laurenatmoonstone.glossgenius.com/services" id="contactLinks">
          Book a service
        </a>
        <div id="footBtm2">
          <a href="mailto:laurenatmoonstone@gmail.com" id="contactLinks2">
            laurenatmoonstone@gmail.com
          </a>
          <br></br>
          <a href="tel:(262) 346-3313" id="contactLinks2">
            (262) 346-3313
          </a>
        </div>
        <br></br>
      </div>
    </div>
  );
};
export default Footer;
