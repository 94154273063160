import hair1 from "../assets/imagesCOM/hair1.jpg";
import hair2 from "../assets/imagesCOM/hair2.jpg";
import hair3 from "../assets/imagesCOM/hair3.jpg";
import hair4 from "../assets/imagesCOM/hair4.jpg";
import hair5 from "../assets/imagesCOM/hair5.jpg";
import hair6 from "../assets/imagesCOM/hair6.2.jpg";
import hair7 from "../assets/imagesCOM/hair7.jpg";
import hair8 from "../assets/imagesCOM/hair8.jpg";
import hair9 from "../assets/imagesCOM/hair9.jpg";
import hair10 from "../assets/imagesCOM/hair10.2.jpeg";
import hair11 from "../assets/imagesCOM/hair11.jpg";
import hair12 from "../assets/imagesCOM/hair12.jpg";
import hair13 from "../assets/imagesCOM/hair13.2.jpg";
import hair14 from "../assets/imagesCOM/hair14.jpg";
import hair15 from "../assets/imagesCOM/hair15.jpg";
import hair16 from "../assets/imagesCOM/hair16.jpg";
import hair17 from "../assets/imagesCOM/hair17.2.jpg";
import hair18 from "../assets/imagesCOM/hair18.jpg";
import Navbar from "../components/Navbar"

const Portfolio = () => {
  return (
    <>
    <Navbar />
    <h1>Portfolio</h1>
      <div id="imageLage" className="cardSection">
        <img src={hair1} alt="..." />
        <img src={hair2} alt="..." />
        <img src={hair3} alt="..." />
        <img src={hair4} alt="..." />
        <img src={hair5} alt="..." />
        <img src={hair6} alt="..." />
        <img src={hair7} alt="..." />
        <img src={hair8} alt="..." />
        <img src={hair9} alt="..." />
        <img src={hair10} alt="..." />
        <img src={hair11} alt="..." />
        <img src={hair12} alt="..." />
        <img src={hair13} alt="..." />
        <img src={hair14} alt="..." />
        <img src={hair15} alt="..." />
        <img src={hair16} alt="..." />
        <img src={hair17} alt="..." />
        <img src={hair18} alt="..." />
      </div>
    </>
  );
};
export default Portfolio;
