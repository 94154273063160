import React from "react";
import "../App.css";
import innerPic from "../assets/images/innerLogo-removebg-preview.png";
import olaPic from "../assets/images/oldLogo-removebg-preview.png";
import hairstoryPic from "../assets/images/hairLogo-removebg-preview.png";
import Particle from "../components/Particle";
import Navbar from "../components/Navbar";

const Product = () => {
  return (
    <>
      <Particle />
      <Navbar />
      <div id="products">
          <h1>Product</h1>
        <div className="row">
          <div className="col">
          <br></br>
          <div className="card">
            <img
              src={innerPic}
              className="card-img-top"
              alt="Hairstory products."
            />
            <div className="card-body">
              <div id="prodzBackz">
                <h5 className="card-title">INNERSENSE</h5>
                <p className="card-text2">
                  Innersense organic beauty is a small organic low toxin hair
                  care line. Even though it's small, they have options for all
                  hair types, and all styling needs. It is my go to line and
                  smells great.
                </p>
                <a
                  href="https://innersensebeauty.com/?sscid=b1k6_kn8sz"
                  id="prodLinks"
                >
                  Purchase here
                </a>
              </div>
            </div>
            </div>
          </div>
          <div className="col">
            <div className="card">
              <img
                src={olaPic}
                className="card-img-top2"
                alt="Olaplex products."
              />
              <div className="card-body">
                <h5 className="card-title">OLAPLEX</h5>
                <p className="card-text2">
                  Olaplex is a reparative treatment line for stressed hair. I
                  recommend this line to anyone who is blonde, or does a lot of
                  frequent heat styling.
                </p>
                <a href="https://olaplex.com/?aff=8535" id="prodLinks">
                  Purchase here
                </a>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card">
              <img
                src={hairstoryPic}
                className="card-img-top3"
                alt="Innersense"
              />
              <div className="card-body">
                <h5 className="card-title">HAIRSTORY</h5>
                <p className="card-text2">
                  Hairstory is a new take on everything hair care. They are a
                  very small line, but have some cool formulations that smell
                  great. My personal favorite is hair balm.
                </p>
                <a href="https://www.hairstory.com/?r=fzzs65" id="prodLinks">
                  Purchase here
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Product;
