import React from "react";
import "../App.css";
import Particle from "../components/Particle";
import Navbar from "../components/Navbar"

const Services = () => {
  return (
    <>
              <Particle />
              <Navbar />
    <div id="servicesContain">
      <center>
        <h1>Services</h1>
        <br></br>
        <div className="">
          <div id="booking" className="">
            <h5 id="makeItDif" className="card-title">
              <a
                href="https://laurenatmoonstone.glossgenius.com/services"
                id="aHere"
              >
                {/* Book your next service */}
                BOOK YOUR NEXT SERVICE HERE
              </a>
            </h5>
            <h6 className="card-subtitle mb-2 text-muted"></h6>
          </div>
        </div>
        <br></br>
        <h2>CUTS</h2>
        <div className="card">
          <div className="card-body">
            <h5 className="card-title">CLIPPER CUT</h5>
            <h6 className="card-subtitle mb-2 text-muted">$45+ | 30 min</h6>
            <p className="card-text2">
              Haircut for very short hair where clipper tools are used. Includes
              wash, minimal styling, and product recommendations.
            </p>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <h5 className="card-title">HAIRCUT</h5>
            <h6 className="card-subtitle mb-2 text-muted">$70+ | 1 hr</h6>
            <p className="card-text2"></p>
          </div>
        </div>
        <br></br>
        <h2>EXPRESS SERVICES</h2>
        <div className="card">
          <div className="card-body">
            <h5 className="card-title">EXPRESS BASE COLOR</h5>
            <h6 className="card-subtitle mb-2 text-muted">$75+ | 1 hr</h6>
            <p className="card-text2">
              On the scalp color application & quick shampoo for 2 inches of
              outgrowth or less. DOES NOT include color pulled thru ends, cut or
              style. Client will leave towel dried.
            </p>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <h5 className="card-title">EXPRESS BASE W/ BLOWDRY</h5>
            <h6 className="card-subtitle mb-2 text-muted">
              $115+ | 1 hr 15 min
            </h6>
            <p className="card-text2">
              On the scalp color application and quick wash for 2 inches of
              outgrowth or less. Includes a quick blow dry. DOES NOT include
              color pulled thru the ends, haircut or hot tools.
            </p>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <h5 className="card-title">EXPRESS SMOOTHING TREATMENT</h5>
            <h6 className="card-subtitle mb-2 text-muted">$150+ | 1 hr</h6>
            <p className="card-text2">
              This keratin based smoothing treatment lasts 6 to 8 weeks
            </p>
          </div>
        </div>
        <br></br>
        <h2>COLOR & CUT</h2>
        <div className="card">
          <div className="card-body">
            <h5 className="card-title">BASE COLOR & HAIRCUT</h5>
            <h6 className="card-subtitle mb-2 text-muted">$160+ | 2 hrs</h6>
            <p className="card-text2">
              Includes retouch of new growth hair up to 4 inches, haircut, style
              & product recommendations. Does not include Hilites, or color
              pulled thru the ends.
            </p>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <h5 className="card-title">ALL OVER COLOR</h5>
            <h6 className="card-subtitle mb-2 text-muted">$185+ | 2 hrs</h6>
            <p className="card-text2">
              Single color from scalp to ends. Can be a refresh or total change.
              Includes wash, Haircut, style and product recommendations.
            </p>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <h5 className="card-title">FULL MAINTENANCE HIGHLIGHT</h5>
            <h6 className="card-subtitle mb-2 text-muted">$200+ | 2.5 hrs</h6>
            <p className="card-text2">
              For someone who already has highlights, just needs them refreshed.
              No more than 4 inches of outgrowth. Includes toning, deep
              conditioning, haircut, style and product recommendation. DOES NOT
              include color between foils or lowlighting.
            </p>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <h5 className="card-title">PARTIAL MAINTENANCE HIGHLIGHT</h5>
            <h6 className="card-subtitle mb-2 text-muted">$160+ | 2 hrs</h6>
            <p className="card-text2">
              For someone who already has highlights, just needs them refreshed.
              No more than 4 inches of outgrowth. Includes toning, deep
              conditioning, haircut, style and product recommendation. DOES NOT
              include color between foils or lowlighting.
            </p>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <h5 className="card-title">BASE RETOUCH W/ PARTIAL HIGHLIGHT</h5>
            <h6 className="card-subtitle mb-2 text-muted">$200+ | 2.5 hrs</h6>
            <p className="card-text2">
              Includes Highlights with foils, new growth touch up between foils
              up to 2 inches, toning, deep conditioning, haircut, style and
              product recommendation. DOES NOT include lowlighting.
            </p>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <h5 className="card-title">FULL HIGHLIGHT W/ ROOT TOUCH UP</h5>
            <h6 className="card-subtitle mb-2 text-muted">$250+ | 3 hrs</h6>
            <p className="card-text2">
              Includes Highlights with foils, new growth touch up inbetween
              foils up to 2 inches, toning, deep conditioning, haircut, style
              and product recommendation. DOES NOT include lowlighting..
            </p>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <h5 className="card-title">NEW FULL HIGHLIGHT</h5>
            <h6 className="card-subtitle mb-2 text-muted">$250+ | 3 hrs</h6>
            <p className="card-text2">
              This is a full highlight for someone who doesn’t currently have
              any highlights in their hair. It includes extra time, product,
              haircut, toning, style and product recommendations.
            </p>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <h5 className="card-title">NEW PARTIAL HIGHLIGHT</h5>
            <h6 className="card-subtitle mb-2 text-muted">$200+ | 2.5 hrs</h6>
            <p className="card-text2">
              This is a partial highlight for someone who doesn’t currently have
              any highlights in their hair. It includes extra time, product,
              haircut, toning, style and product recommendations.
            </p>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <h5 className="card-title">ON THE SCALP BLEACH RETOUCH</h5>
            <h6 className="card-subtitle mb-2 text-muted">$200+ | 2.5 hrs</h6>
            <p className="card-text2">
              On the scalp bleach touch up for two inches of new growth or less,
              includes toning, deep conditioning, cut, style, and product
              recommendations.
            </p>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <h5 className="card-title">DOUBLE PROCESS COLOR FOR VIVIDS</h5>
            <h6 className="card-subtitle mb-2 text-muted">$450+ | 4 hrs</h6>
            <p className="card-text2">
              Includes bleaching of the hair, toning, deep conditioning,
              haircut, VIVID color application, style and product
              recommendation. Pricing and timing vary per client, complimentry
              consultation is strongly recommended
            </p>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <h5 className="card-title">FULL BALAYAGE</h5>
            <h6 className="card-subtitle mb-2 text-muted">$275+ | 3 hrs</h6>
            <p className="card-text2">
              Includes a full balayage application, wash, toning, deep
              conditioning, Haircut, Style, and product recommendation. Does not
              include On the scalp color or lowlights. Price varies per client.
              Complimentry consultation strongly recommended.
            </p>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <h5 className="card-title">PARTIAL BALAYAGE</h5>
            <h6 className="card-subtitle mb-2 text-muted">$220+ | 2.5 hrs</h6>
            <p className="card-text2">
              Includes Highlights with foils, new growth touch up inbetween
              foils up to 2 inches, toning, deep conditioning, haircut, style
              and product recommendation. DOES NOT include lowlighting..
            </p>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <h5 className="card-title">SCALP TO ENDS BLEACH & TONE</h5>
            <h6 className="card-subtitle mb-2 text-muted">$275+ | 3 hrs</h6>
            <p className="card-text2">
              Includes sigle process scalp to ends bleach application, toning,
              deep conditioning, haircut, style and product recommendation.
              Price can vary by client depending on product used, complimentry
              consultation is strongly encouraged.
            </p>
          </div>
        </div>
        <br></br>
        <h2>OTHER</h2>
        <div className="card">
          <div className="card-body">
            <h5 className="card-title">CONSULTATION</h5>
            <h6 className="card-subtitle mb-2 text-muted">$0 | 15 min</h6>
            <p className="card-text2">
              Let's chat about your hair, free of charge! Can be used for
              anything! (color correction, extensions, or even just a new cut)
              We can make sure we are on the same page and you have a good idea
              of the cost, upkeep, and whether this is a realistic idea/fitting
              style for you!
            </p>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <h5 className="card-title">ADD ON</h5>
            <h6 className="card-subtitle mb-2 text-muted">$45+ | 30 min</h6>
            <p className="card-text2">
              This is a 30min add on slot to be used for the addition of one of
              the following- shadow root, toner, low lighting, or color pull
              thru. Must be added on to other service unless just doing a tone
              &go
            </p>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <h5 className="card-title">STYLE</h5>
            <h6 className="card-subtitle mb-2 text-muted">$60+ | 1 hr</h6>
            <p className="card-text2">
              Includes wash, style, and hot tools. This is not an updo or formal
              style.
            </p>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <h5 className="card-title">STYLE FOR EXTENSIONS</h5>
            <h6 className="card-subtitle mb-2 text-muted">$70+ | 1 hr</h6>
            <p className="card-text2">
              Includes wash, style and hot tools with extra time to manage any
              kind of extensions. This is not a formal style or updo.
            </p>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <h5 className="card-title">SIGNATURE SMOOTHING TREATMENT</h5>
            <h6 className="card-subtitle mb-2 text-muted">$350+ | 2.5 hrs</h6>
            <p className="card-text2">
              This keratin-based smoothing treatment lasts up to five months.
              Customizable smooth results.
            </p>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <h5 className="card-title">EXPRESS SMOOTHING ADD-ON</h5>
            <h6 className="card-subtitle mb-2 text-muted">$100+ | 30 min</h6>
            <p className="card-text2">
              This keratin based smoothing treatment can be added onto any
              service. Results last 6 to 8 weeks
            </p>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <h5 className="card-title">K18 ADD-ON</h5>
            <h6 className="card-subtitle mb-2 text-muted">$20+ | 10 min</h6>
            <p className="card-text2"></p>
          </div>
        </div>
      </center>
    </div>
    </>
  );
};
export default Services;
