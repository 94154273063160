import React, { useState } from "react";
import "../../src/App.css";
import { Link } from "react-router-dom";
import Logo from "../../src/assets/icons/moonstoneLogo.png";
import NavLogo from "../../src/assets/icons/moonstoneLogo.png";

const Navbar = () => {
  const [view, setView] = useState("/");

  const handleLinkClick = (event) => {
    setView(event.target.textContent);
    document.getElementById("closeBtn").click();
  };
  return (
    <div >
      <nav id="navRelative" className="navbar sticky navbar-expand-lg bg-body-tertiary x-4">
        <div className="container-fluid">
          <img src={Logo} id="navLogoDT" />
          <br></br>
          <div id="navButtons">
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasNavbar"
              aria-controls="offcanvasNavbar"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="offcanvas offcanvas-end"
              tabindex="-1"
              id="offcanvasNavbar"
              aria-labelledby="offcanvasNavbarLabel"
            >
              <div className="offcanvas-header">
                <button
                  id="closeBtn"
                  type=""
                  className="btn-close"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                ></button>

                <h5 className="offcanvas-title" id="offcanvasNavbarLabel">
                  <img id="navLogo" src={NavLogo} />
                </h5>
              </div>
              <div className="offcanvas-body">
                <ul className="navbar-nav justify-content-end flex-grow-1 pe-3">
                  <li className="nav-item">
                    <Link
                      id="closeBtn"
                      exact
                      to="/"
                      className="nav-link"
                      activeClassName="active"
                      onClick={handleLinkClick}
                    >
                      Home
                    </Link>
                  </li>
                  <li className="nav-item dropdown">
                    <Link
                      to="/Portfolio"
                      className="nav-link"
                      activeClassName="active"
                      onClick={handleLinkClick}
                    >
                      Portfolio
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/Services"
                      className="nav-link"
                      activeClassName="active"
                      onClick={handleLinkClick}
                    >
                      Services
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/Product"
                      className="nav-link"
                      activeClassName="active"
                      onClick={handleLinkClick}
                    >
                      Product
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/Testimonials"
                      className="nav-link"
                      activeClassName="active"
                      onClick={handleLinkClick}
                    >
                      Testimonials
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/FAQ"
                      className="nav-link"
                      activeClassName="active"
                      onClick={handleLinkClick}
                    >
                      FAQ
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/Contact"
                      className="nav-link"
                      activeClassName="active"
                      onClick={handleLinkClick}
                    >
                      Contact
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};
export default Navbar;
