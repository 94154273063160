import React from "react";
import "../../src/App.css";
import Particle from "../components/Particle";
import Navbar from "../components/Navbar"

const FAQ = () => {
  return (
    <div>
          <Particle />
          <Navbar />
      <div id="faq">
        <div id="faqP">
          <h1>FAQ</h1>
          <br></br>
          <div className="card card-body2">
          <h5 className="faqQ">How can I book an appointment with you?</h5>
          <p id="faqA">
            The easiest way to book an appointment 24/7 is online! You have to
            put a card on file, but that card is not charged and a different
            form of payment can be used at the time of the service if needed.{" "}
          </p>
          </div>
          <br></br>
          <div className="card card-body2">
          <h5 className="faqQ">How do I know what to book?</h5>
          <p id="faqA">
            Each service has a description associated with it- choose the best
            service for what you think you need. Most of my services include any
            extras (extra bowls of color, conditioning treatments, haircuts ) If
            something is booked incorrectly, we might have to compromise on some
            thing due to timing. But, If you have any questions, you can always
            text me at <a href="tel:(262)-346-3313" id="contactLinks">262-346-3313</a>. You can also set up a free consultation to
            discuss your goals and options ahead of time.
          </p>
          </div>
          <br></br>
          <div className="card card-body2">
          <h5 className="faqQ">Color line/brands?</h5>
          <p id="faqA">
            All of my color services are performed using Oligo color. My
            permanent color line is ammonia and PPD free. Most of my styling
            products, as well as shampoo/conditioner that I use behind the chair
            is all from Innersense organic beauty. They are a low tox haircare
            line scented with essential oils that provides great performance.
          </p>
          </div>
          <br></br>
          <br></br>
          <h2>Services</h2>
          <br></br>
          <h2 className="faqQ">Extensions</h2>
          <br></br>
          <div className="card card-body2">
          <h5 className="faqQ">How much do extensions cost?</h5>
          <p id="faqA">
            As much as I would love to give people a quick price for extensions,
            it’s not that easy. The price of your extensions depends on a
            multitude of factors, including- length of hair desired thickness of
            hair desired how thick your natural hair is color of the hair The
            method of installation As well as general maintenance which varies
            on the type of installation I know that’s not a satisfying answer,
            but I offer free consultations where you can come in and get a
            personalized hair care plan and quote so you know exactly what to
            expect!
          </p>
          </div>
          <br></br>
          <h2 className="faqQ">Smoothing Treatments</h2>
          <br></br>
          <div className="card card-body2">
          <h5 className="faqQ">What types of smoothing treatments do you offer?</h5>
          <p id="faqA">
          The brand I use is keratin complex. I currently offer an Express smoothing treatment which is most commonly used as an add-on. It lasts 6 to 8 weeks and provides frizz control more than straightening. I also offer a signature smoothing treatment which is a lengthier service, but it lasts up to five months. This one is a bit more smoothing, but still isn’t going to completely straighten the hair.
          </p>
          </div>
          <br></br>
          <div className="card card-body2">
          <h5 className="faqQ">What are smoothing treatments for?</h5>
          <p id="faqA">
          They can be used for a multitude of reasons, but the most common reasons I recommend them is for frizz control, hair repair, color locking, and just easier day today styling. You don’t have to have a super curly hair to get one!
          </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQ;
