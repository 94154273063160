import React from "react";
import "../src/App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Footer from "../src/components/Footer";
import Home from "../src/pages/Home";
import Portfolio from "../src/pages/Portfolio";
import Contact from "../src/pages/Contact";
import FAQ from "../src/pages/FAQ";
import Product from "../src/pages/Product";
import Services from "../src/pages/Services";
import Testimonials from "../src/pages/Testimonials";

function App() {
  return (
    // basename="/moonstone-react"
    <BrowserRouter>
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route exact path="home" element={<Home />}></Route>
          <Route path="/portfolio" element={<Portfolio />}></Route>
          <Route path="services" element={<Services />}></Route>
          <Route path="product" element={<Product />}></Route>
          <Route path="contact" element={<Contact />}></Route>
          <Route path="faq" element={<FAQ />}></Route>
          <Route path="testimonials" element={<Testimonials />}></Route>
        </Routes>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
