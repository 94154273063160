import React from "react";
import "../../src/App.css";
import Particle from "../components/Particle";

import homeImg from "../assets/images/main2.jpg"
import Navbar from "../components/Navbar"
const Home = () => {

  return (
    <div id="homeContain">
        <Particle />
      <Navbar />
<img className="bioPic" src={homeImg} />
      <center>
    <p className="bioText">
<h2 id="bioHello">Hello!</h2>
    I am Lauren, owner of Moonstone Studio. I graduated from The Institute of Beauty and Wellness in 2009. My main focus has always been hair, specifically color! I love everything from big bold changes, crisp beautiful blondes, to more simple gray coverage or blending. I have completed hundreds of hours of outside education throughout my years as a stylist. In 2015 I completed the Aveda Mastery Series for color. Most recently I became certified in Hand tied extensions.
      <br></br>
      <br></br>
      I originally got into the industry to express my creativity and felt it would be a fun career. While that still holds true, my biggest passion is creating a great relationship with each of my guests. It's so unique to be able to be a part of so many journeys from so many different walks of life. I love coming to work and getting to spend time with some of my best friends.
      <br></br>
      <br></br>
      When I'm not at the salon, I'm normally hanging out at home with my husband & 3 cats. I am admittedly a homebody. I like to read, play video games, watch thought provoking documentaries or mind numbing reality shows, and drink entirely too much coffee. Recently my husband and I have been excited about traveling. I can't wait to tell you about all the places we go!</p>
      </center>
    </div>
  );
};

export default Home;
